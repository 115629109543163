import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { INJECTOR, Injectable, inject } from '@angular/core';
import { FirebaseError } from '@angular/fire/app';
import {
  Auth,
  CustomParameters,
  FacebookAuthProvider,
  GoogleAuthProvider,
  MultiFactorError,
  MultiFactorResolver,
  OAuthCredential,
  OAuthProvider,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaParameters,
  RecaptchaVerifier,
  User,
  UserCredential,
  applyActionCode,
  authState,
  browserSessionPersistence,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getMultiFactorResolver,
  idToken,
  indexedDBLocalPersistence,
  linkWithCredential,
  multiFactor,
  reload,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  updatePassword,
  updateProfile,
  verifyPasswordResetCode,
} from '@angular/fire/auth';
import { Capacitor } from '@capacitor/core';
import { FirebaseAuthentication as CapacitorFirebaseAuth } from '@capacitor-firebase/authentication';
import { setPersistence } from '@firebase/auth';
import { Platform } from '@ionic/angular';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import { EMPTY, Observable, firstValueFrom } from 'rxjs';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { AppAnalyticsService } from '../app-analytics.service';
import { TProvider } from './components/auth-providers/auth-providers.component';
import { LinkCredentialsDialogComponent } from './components/auth-providers/link-credentials-dialog/link-credentials-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly dialogService = inject(TuiDialogService);
  private readonly injector = inject(INJECTOR);
  private http = inject(HttpClient);
  private environmentService = inject(EnvironmentService);
  private platform = inject(Platform);
  private auth = inject(Auth, { optional: true });
  private analyticsService = inject(AppAnalyticsService);

  isNativePlatform = Capacitor.isNativePlatform();
  isIOS = this.isNativePlatform && Capacitor.getPlatform() === 'ios';
  isAndroid = this.isNativePlatform && Capacitor.getPlatform() === 'android';
  public readonly firebaseUser$: Observable<User | null> = EMPTY;
  public readonly firebaseIdToken$: Observable<null | string> = EMPTY;

  constructor() {
    if (this.auth) {
      this.firebaseUser$ = authState(this.auth!);
      this.firebaseIdToken$ = idToken(this.auth!);
    }
  }

  applyActionCode(actionCode: string) {
    return applyActionCode(this.auth!, actionCode);
  }

  getRecaptchaVerifierFor(id: string, params: RecaptchaParameters = {}) {
    return new RecaptchaVerifier(this.auth!, id, params);
  }

  verifyPhoneNumberToEnrollSecondFactor(
    phoneNumber: string,
    recaptchaVerifier: RecaptchaVerifier,
  ) {
    if (!this.auth?.currentUser) {
      return Promise.reject('currentUser is null');
    }

    if (this.isIOS) {
      return CapacitorFirebaseAuth.verifyPhoneNumberToEnrollSecondFactor({
        phoneNumber,
      }).then(({ verificationId }) => verificationId);
    }

    return multiFactor(this.auth.currentUser)
      .getSession()
      .then((session) => {
        const phoneAuthProvider = new PhoneAuthProvider(this.auth!);
        return phoneAuthProvider
          .verifyPhoneNumber({ phoneNumber, session }, recaptchaVerifier)
          .then((verificationId) => verificationId);
      });
  }

  verifySecondFactor(verificationId: string, verificationCode: string) {
    if (!this.auth?.currentUser) {
      return Promise.reject('currentUser is null');
    }

    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    return multiFactor(this.auth.currentUser).enroll(multiFactorAssertion);
  }

  sendSMSVerificationCodeToSignIn(
    error: MultiFactorError,
    recaptchaVerifier: RecaptchaVerifier,
  ) {
    const resolver = getMultiFactorResolver(this.auth!, error);
    const phoneAuthProvider = new PhoneAuthProvider(this.auth!);
    return phoneAuthProvider
      .verifyPhoneNumber(
        { multiFactorHint: resolver.hints[0], session: resolver.session },
        recaptchaVerifier,
      )
      .then((verificationId) => ({ verificationId, resolver }));
  }

  resolveMultiFactorSignIn(
    resolver: MultiFactorResolver,
    verificationId: string,
    verificationCode: string,
  ) {
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    return resolver
      .resolveSignIn(multiFactorAssertion)
      .then((userCredential) => {
        this.analyticsService.logEvent('login', {
          method: 'emailAndPasswordWith2FA',
        });
        return userCredential;
      });
  }

  async getIdToken() {
    if (this.auth!) {
      const token = await firstValueFrom(this.firebaseIdToken$);
      return token;
    }

    return null;
  }

  // Sign up
  signUpWithEmail(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth!, email, password).then(
      (userCredential) => {
        this.analyticsService.logEvent('sign_up', {
          method: 'emailAndPassword',
        });
        return userCredential;
      },
    );
  }

  // Sign in
  async signInWithEmail(
    email: string,
    password: string,
    rememberMe: 'skip' | boolean = true,
  ) {
    let verificationId: string | undefined;

    if (rememberMe !== 'skip') {
      try {
        await setPersistence(
          this.auth!,
          rememberMe ? indexedDBLocalPersistence : browserSessionPersistence,
        );
      } catch (e) {
        // reject if user has unchecked 'remember me' as the default persistence is browserLocalPersistence
        if (!rememberMe) {
          return Promise.reject(e);
        }
      }
    }

    if (this.isIOS) {
      // To be able to handle second factor enrollment and verification on iOS
      // we have to be signed in on native layer
      try {
        await CapacitorFirebaseAuth.signInWithEmailAndPassword({
          email,
          password,
        });
      } catch (e) {
        // If a second factor is already enrolled,
        // an SMS is automatically send and the verificationId is returned as errorMessage
        if (
          e &&
          typeof e === 'object' &&
          // @ts-expect-error: If e is an object we can read e.code
          e.code === 'auth/multi-factor-auth-required'
        ) {
          // @ts-expect-error: If e is an object we can read e.errorMessage
          verificationId = e.errorMessage;
        }
      }
    }

    try {
      const result = await signInWithEmailAndPassword(
        this.auth!,
        email,
        password,
      );
      this.analyticsService.logEvent('login', { method: 'emailAndPassword' });
      return Promise.resolve(result);
    } catch (e) {
      // On iOS, if a second factor auth is required,
      // we set the already known verificationId and a resolver in error customData
      // for the AbstractMultiFactorAuthHandler to directly handle it
      if (
        this.isIOS &&
        e instanceof FirebaseError &&
        e.code === 'auth/multi-factor-auth-required'
      ) {
        const resolver = getMultiFactorResolver(
          this.auth!,
          e as MultiFactorError,
        );
        e.customData = e.customData || {};
        e.customData.verificationId = verificationId;
        e.customData.resolver = resolver;
      }
      return Promise.reject(e);
    }
  }

  async signInWithFFBId(
    FFBId: string,
    password: string,
    rememberMe: 'skip' | boolean = true,
  ) {
    try {
      const email = await firstValueFrom(
        this.http.post<string>(
          this.environmentService.get('apiBaseUrl') +
            '/public/auth/get-email-from-ffb-id',
          {
            FFBId,
            password,
          },
        ),
      );
      return this.signInWithEmail(email, password, rememberMe);
    } catch (error) {
      let errorCode = '';
      if (error instanceof HttpErrorResponse) {
        if (error.error.message === 'INVALID_PASSWORD') {
          errorCode = 'auth/wrong-password';
        } else if (error.error.code === 101) {
          errorCode = 'auth/ffb-id-not-found';
        } else if (error.error.code === 102) {
          errorCode = 'auth/wrong-password';
        }
      }
      return Promise.reject(new FirebaseError(errorCode, ''));
    }
  }

  async signInWithApple(
    customParameters?: CustomParameters,
    rememberMe = true,
  ) {
    let credential;
    const provider = new OAuthProvider('apple.com');
    if (customParameters) {
      provider.setCustomParameters(customParameters);
    }
    if (this.isNativePlatform) {
      try {
        const result = await CapacitorFirebaseAuth.signInWithApple({
          skipNativeAuth: true,
        });
        credential = provider.credential({
          idToken: result.credential?.idToken,
          rawNonce: result.credential?.nonce,
        });
      } catch (e) {
        return Promise.reject(e);
      }
    }
    return this.signInWithProvider(provider, credential, rememberMe).then(
      (userCredential) => {
        this.analyticsService.logEvent('login', { method: 'Apple' });
        return userCredential;
      },
    );
  }

  async signInWithFacebook(
    customParameters?: CustomParameters,
    rememberMe = true,
  ) {
    let credential;
    const provider = new FacebookAuthProvider();
    provider.addScope('email');
    provider.addScope('public_profile');
    if (customParameters) {
      provider.setCustomParameters(customParameters);
    }
    if (this.isNativePlatform) {
      try {
        const result = await CapacitorFirebaseAuth.signInWithFacebook();
        credential = FacebookAuthProvider.credential(
          result.credential?.accessToken as string,
        );
      } catch (e) {
        return Promise.reject(e);
      }
    }
    return this.signInWithProvider(provider, credential, rememberMe).then(
      (userCredential) => {
        this.analyticsService.logEvent('login', { method: 'Facebook' });
        return userCredential;
      },
    );
  }

  async signInWithGoogle(
    customParameters?: CustomParameters,
    rememberMe = true,
  ) {
    let credential;
    const provider = new GoogleAuthProvider();
    if (customParameters) {
      provider.setCustomParameters(customParameters);
    }
    if (this.isNativePlatform) {
      try {
        const result = await CapacitorFirebaseAuth.signInWithGoogle();
        credential = GoogleAuthProvider.credential(result.credential?.idToken);
      } catch (e) {
        return Promise.reject(e);
      }
    }
    return this.signInWithProvider(provider, credential, rememberMe).then(
      (userCredential) => {
        this.analyticsService.logEvent('login', { method: 'Google' });
        return userCredential;
      },
    );
  }

  async signInWithProvider(
    provider: FacebookAuthProvider | GoogleAuthProvider | OAuthProvider,
    credential?: OAuthCredential,
    rememberMe = true,
  ): Promise<UserCredential> {
    let userCredential;

    try {
      await setPersistence(
        this.auth!,
        rememberMe ? indexedDBLocalPersistence : browserSessionPersistence,
      );
    } catch (e) {
      // reject if user has unchecked 'remember me' as the default persistence is browserLocalPersistence
      if (!rememberMe) {
        return Promise.reject(e);
      }
    }

    try {
      // on native mobile apps
      if (this.isNativePlatform && credential) {
        userCredential = await signInWithCredential(this.auth!, credential);
      } else {
        // in a web browser running in a mobile device
        if (this.platform.is('mobileweb')) {
          userCredential = await signInWithRedirect(this.auth!, provider);
        }
        // on desktop
        userCredential = await signInWithPopup(this.auth!, provider);
      }

      return Promise.resolve(userCredential);
    } catch (error) {
      if (
        error instanceof FirebaseError &&
        error.code === 'auth/account-exists-with-different-credential'
      ) {
        const {
          // @ts-expect-error customData.email do exist in this particular error
          customData: { email },
        } = error;

        const credentialFromError = OAuthProvider.credentialFromError(error);

        try {
          const providers = await fetchSignInMethodsForEmail(this.auth!, email);
          let userCredentialFromOtherProvider: UserCredential | null = null;
          let proceedWith: TProvider | null = null;

          await this.dialogService
            .open<TProvider | null>(
              new PolymorpheusComponent(
                LinkCredentialsDialogComponent,
                this.injector,
              ),
              {
                data: {
                  providers: providers.map((provider) =>
                    provider.replace('.com', ''),
                  ),
                  email,
                },
                dismissible: true,
              },
            )
            .subscribe({
              next: (chosenProvider) => (proceedWith = chosenProvider),
              complete: async () => {
                if (proceedWith === 'google') {
                  userCredentialFromOtherProvider = await this.signInWithGoogle(
                    {
                      login_hint: email,
                    },
                  );
                } else if (proceedWith === 'facebook') {
                  userCredentialFromOtherProvider =
                    await this.signInWithFacebook({
                      login_hint: email,
                    });
                } else if (proceedWith === 'apple') {
                  userCredentialFromOtherProvider = await this.signInWithApple({
                    login_hint: email,
                  });
                }

                if (userCredentialFromOtherProvider && credentialFromError) {
                  await linkWithCredential(
                    userCredentialFromOtherProvider.user,
                    credentialFromError,
                  );
                }
              },
            });

          if (userCredentialFromOtherProvider) {
            return Promise.resolve(userCredentialFromOtherProvider);
          } else {
            this.signOut();
            return Promise.reject(
              new FirebaseError(
                'auth/popup-closed-by-user',
                'Popup closed by user',
              ),
            );
          }
        } catch (e) {
          this.signOut();
          return Promise.reject(e);
        }
      } else {
        return Promise.reject(error);
      }
    }
  }

  // Sign out
  signOut() {
    this.analyticsService.logEvent('logout');
    if (this.isNativePlatform) {
      CapacitorFirebaseAuth.signOut();
    }
    return signOut(this.auth!);
  }

  // Password reset
  sendPasswordResetEmail(emailOrFFBId: string) {
    return firstValueFrom(
      this.http.post<string>(
        this.environmentService.get('apiBaseUrl') +
          '/public/auth/reset-password/' +
          emailOrFFBId,
        {},
      ),
    );
  }

  verifyPasswordResetCode(actionCode: string) {
    return verifyPasswordResetCode(this.auth!, actionCode);
  }

  confirmPasswordReset(actionCode: string, newPassword: string) {
    return confirmPasswordReset(this.auth!, actionCode, newPassword);
  }

  verifyEmail(actionCode: string) {
    return applyActionCode(this.auth!, actionCode)
      .then(() => firstValueFrom(this.firebaseUser$))
      .then((user) => {
        if (user) {
          reload(user);
        }
      });
  }

  // Update password
  updatePassword(newPassword: string) {
    if (!this.auth?.currentUser) {
      return Promise.reject('currentUser is null');
    }

    return updatePassword(this.auth.currentUser, newPassword);
  }

  // Update profile
  updateProfile(user: User, data: Parameters<typeof updateProfile>[1]) {
    return updateProfile(user, data);
  }
}
