<ng-container *transloco="let t">
  <header
    class="full-height flex flex-row items-center justify-between pr1"
    *tuiLet="adminService.entities$ | async as entities"
    [class.pl2]="entities?.length === 1"
  >
    <div class="flex flex-row items-center">
      <button
        appearance="icon"
        tuiIconButton
        iconStart="@tui.menu"
        class="menu-button"
        (click)="onClick()"
        (tuiActiveZoneChange)="onActiveZone($event)"
      >
        <ffb-admin-layout-navigation
          class="min-full-height"
          *tuiSidebar="!!(open$ | async)"
        ></ffb-admin-layout-navigation>
      </button>
      @if (entities?.length > 1) {
        <tui-elastic-container>
          <a
            tuiButton
            routerLink="/admin"
            [appearance]="outButtonHovered ? 'whiteblock' : 'icon'"
            class="switch-entity-button tui-font-text ml1"
            size="s"
            [class.mr2]="outButtonHovered"
            [state]="{ forceDisplayForm: true }"
            (mouseover)="outButtonHovered = true"
            (mouseout)="outButtonHovered = false"
          >
            <tui-icon icon="@tui.log-out" class="upside-down"></tui-icon>
            @if (outButtonHovered) {
              {{ t('admin.entitySearchComboBox.switchEntity') }}
            }
          </a>
        </tui-elastic-container>
      }
      <p
        *tuiLet="adminService.currentEntity$ | async as currentEntity"
        class="uppercase-semi-bold"
        [tuiSkeleton]="!currentEntity"
      >
        {{ utils.getEntityLabel(currentEntity) }}
      </p>
    </div>
    @if (signedInPerson$ | async; as signedInPerson) {
      <div
        tuiDropdownAlign="right"
        [tuiDropdown]="userMenu"
        [(tuiDropdownOpen)]="userMenuExpanded"
      >
        <a tuiButton appearance="text" size="s" class="lowercase gap1">
          <ffb-user-avatar [person]="signedInPerson" size="s"></ffb-user-avatar>
          <div
            class="full-height flex flex-column items-start justify-around mr1"
          >
            <span class="line-height-1 color-text-tertiary">
              {{ t('notifications.welcome') }}
            </span>
            <span class="line-height-1 color-black semi-bold">
              {{ signedInPerson.firstName }}
            </span>
          </div>
          <tui-icon
            icon="@tui.chevron-down"
            class="transform-ease-in-out"
            [class.upside-down]="userMenuExpanded"
          ></tui-icon>
        </a>

        <ng-template #userMenu>
          <tui-data-list class="user-dashboard-menu" role="menu">
            <!--  LINK TO USER DASHBOARD  -->
            <tui-opt-group>
              <a tuiOption role="menuitem" routerLink="/user/dashboard">
                <div>
                  <p class="bold">
                    {{
                      signedInPerson
                        | personInformation: { lastName: true, firstName: true }
                    }}
                  </p>
                  {{ signedInPerson.ffbId }}
                </div>
              </a>
            </tui-opt-group>
            <!-- LOGOUT BUTTON -->
            <tui-opt-group>
              <a tuiOption role="menuitem" (click)="logout()">
                <tui-icon
                  icon="/assets/icon-set/logout.svg"
                  class="color-accent-1 mr1"
                ></tui-icon>
                {{ t('nav.user.logout') }}
              </a>
            </tui-opt-group>
          </tui-data-list>
        </ng-template>
      </div>
    }
  </header>
</ng-container>
